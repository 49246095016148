import { NetworkType } from '@airgap/beacon-sdk'
import PhoneIcon from './graphics/phone.js'
import EmailIcon from './graphics/email.js'
import GovIcon from './graphics/gov.js'
import WorldCoinIcon from './graphics/worldcoin.js'
import { Over18, ChainBorn, Defi } from './components/cards'
import { TfiTwitter } from 'react-icons/tfi'

export const IPFS_BASE_URI = 'https://ipfs.asbjornenge.com'
export const RENEW_DAYS = 365
export const TEZOS_NETWORK_DEFAULT = 'mainnet'
export const TEZOS_NETWORKS = {
  mainnet: {
    rpc: 'https://rpc.chainborn.xyz',
    rpcFallback: ['https://mainnet.smartpy.io','https://rpc.tzbeta.net/'],
    name: 'mainnet',
    type: NetworkType.MAINNET,
    label: 'Mainnet',
    contract: 'KT18gEzBhpStbhAqTAAqYzfmNpwMboUU3v1g',
    idz_contract: 'KT1WapdVeFqhCfqwdHWwTzSTX7yXoHgiPRPU',
    xidz_contract: 'KT1PMfohCLwrnazZEGSEpewhCShyXnPv5i2p',
    idzdex_contract: 'KT18rJtJNmwTfbJMinWqHzpkLeBQa4BVqGoJ', 
    xfarm_contract: 'KT1GAK5Py1Hy6qFQBGVHbgTjqvAjCy8UMs2E',
    testnet: false,
    tzstats: 'https://tzstats.com',
    passbaseKey: 'Zm6Xq6TMegtfGZLyxUV98FsNAXq219VzeEKUkp2xdnzwij447uTqhtpKlKKcuACV'
  },
  ghostnet: {
    rpc: 'https://ghostnet.smartpy.io',
    name: 'ghostnet',
    type: 'ghostnet',
    label: 'Ghostnet',
    contract: 'KT1K2i7gcbM9YY4ih8urHBDbmYHLUXTWvDYj',
    testnet: true,
    tzstats: 'https://ghost.tzstats.com',
    passbaseKey: 'LLsKsXoC7lvv3jUWC79m8coagS3tkoIASz1pKeW6koSU5ruhtMtWi7Mkm4elNrKw'
  },
}

export const CARDS = {
  'over_18': {
    id: 'over_18',
    label: 'Proof of Over 18',
    sublabel: 'You are over 18 years old',
    issuer: 'Altme',
    issuerLink: 'https://altme.io',
    component: Over18
  },
  'urn:uuid:0e57d9-0591-4444-95c0-9b363453578': {
    id: 'urn:uuid:0e57d9-0591-4444-95c0-9b363453578',
    label: 'Loyalty Card',
    sublabel: 'Chainborn Game',
    issuer: 'Altme',
    issuerLink: 'https://altme.io',
    component: ChainBorn
  },
  'defi_compliance': {
    id: 'defi_compliance',
    label: 'Defi Compliance',
    sublabel: 'KYC',
    issuer: 'Altme',
    issuerLink: 'https://issuer.talao.co/verifier/defi/tezid',
    showIfMissing: true,
    component: Defi
  }
}

export const TEZID_PROOFTYPES = {
  email: {
    id: 'email',
    label: 'Email',
    icon: <EmailIcon />,
    requirementText: 'This proof requires a unique email address. Valid for 365 days.'
  },
  phone: {
    id: 'phone',
    label: 'Phone',
    icon: <PhoneIcon />,
    requirementText: 'This proof requires a unique phone number that can receive SMS. Valid for 365 days.'
  },
  twitter: {
    id: 'twitter',
    label: 'Twitter',
    icon: <TfiTwitter />, 
    requirementText: 'This proof verified ownership of a Twitter account. NOTE: Handle will be available on-chain in clear text! Valid for 365 days. ' 
  },
  gov: {
    id: 'gov',
    label: 'Gov. ID',
    icon: <GovIcon />, 
    requirementText: 'This proof requires a valid passport, drivers license or national ID card. Valid for 365 days.'
  },
  worldid: {
    id: 'worldid',
    label: 'World ID',
    icon: <WorldCoinIcon />, 
    requirementText: 'This proof requires a valid WorldID from Worldcoin. It needs to verified by an Orb.'
  }
}

export const TEZID_APPS = [
  {
    id: 'altme',
    kyc: false, 
    kyc_testnet: false, 
    email: false,
    applist: true, 
    name: 'Altme',
    tagline: 'SSI Wallet',
    icon: 'altme.png',
    url: 'https://altme.io/',
    color: '#5800FF',
    borderColor: '#5800FF',
    descriptionBackgroundColor: '#5800FF',
    descriptionColor: 'white',
    description: 'Altme is a wallet focused on Self-Sovereign Identity. Altme is using TezID to make their SSI claims available on-chain.' 
  },
  {
    id: 'chainborn',
    kyc: false, 
    kyc_testnet: false, 
    email: true,
    applist: true, 
    name: 'ChainBorn',
    tagline: 'TCG for NFT PFPs',
    icon: 'chainborn.png',
    url: 'https://chainborn.xyz/',
    color: '#1F3731',
    borderColor: '#1F3731',
    descriptionBackgroundColor: '#068F78',
    descriptionColor: 'white',
    description: 'ChainBorn is using TezID Profiles for their "Player Profiles". And will also be the first customer using the new TezID Notification Channels!' 
  },
  {
    id: 'versum',
    kyc: true, 
    kyc_testnet: true, 
    applist: true, 
    name: 'Versum',
    tagline: 'Next-gen NFT marketplace and metaverse',
    icon: 'versum-inverted.png',
    url: 'https://versum.xyz/',
    color: '#303030',
    borderColor: 'black',
    descriptionBackgroundColor: 'black',
    descriptionColor: 'white',
    description: 'Versum is using TezID for identity verification and KYC for high-end artists and collectors on their NFT marketplace and metaverse.'
  },
  {
    id: 'dns',
    kyc: false, 
    applist: true, 
    name: 'DNS',
    tagline: 'The profile for your Tezos wallet',
    icon: 'dns.jpg',
    url: 'https://dns.xyz/',
    color: '#303030',
    borderColor: '#C39E5E',
    descriptionBackgroundColor: '#C39E5E',
    descriptionColor: 'white',
    description: 'DNS turns your wallet activity into a social profile. You can show your links and showcase your NFTs in a beautiful, decentralized profile.'
  },
  {
    id: 'crunchy',
    kyc: true, 
    applist: true, 
    name: 'Crunchy',
    tagline: 'Defi-as-a-Service',
    icon: 'crunchy.png',
    url: 'https://crunchy.network/',
    color: '#E7393F',
    borderColor: '#F2474E',
    descriptionBackgroundColor: '#F2474E',
    descriptionColor: 'white',
    description: 'Crunchy is leveraging the TezID Government ID Proofs w/KYC for compliance and security on their platform.',
  },
  {
    id: 'yaynay',
    kyc: true, 
    applist: true, 
    name: 'YayNay',
    tagline: 'Collective decision-making',
    icon: 'yaynay.png',
    url: 'https://yaynay.app',
    color: '#344960',
    borderColor: '#CC8561',
    descriptionBackgroundColor: '#CC8561',
    descriptionColor: 'white',
    description: 'YayNay is leveraging TezID to have increased confidence that voters on their platform are actualt humans.',
  },
]
